import React, { FC } from 'react';

export interface ContactPageProps {
};

export const ContactPage: FC<ContactPageProps> = () => {
  return (
    <>
      <h1>contact information</h1>
      <p>for work enquiries, please contact me via <a href="https://www.linkedin.com/in/dluces" target="_blank" rel="noopener noreferrer">linked in</a></p>
    </>
  );
};

ContactPage.defaultProps = {
};
import React, { FC } from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'

export interface AppWrapperProps {
};

export const AppWrapper: FC<AppWrapperProps> = ({children}) => {
  return (
    <Fabric className="App">
      {children}
    </Fabric>
  );
};

AppWrapper.defaultProps = {
};
import React, { FC } from 'react';

export interface AboutPageProps {
};

export const AboutPage: FC<AboutPageProps> = () => {
  return (
    <>
      <h1>about me</h1>
      <p>software development is my passion</p>
      <p>i also enjoy other things like dogs and plants</p>
      <p>here's a bit about my professional me</p>
      <h2>work</h2>
      <p>i'm a software engineer</p>
      <ul>
        <li><span className="org">microsoft</span> <span className="time">nov 2019 - present</span></li>
        <li><span className="org">mover</span> <span className="time">apr 2016 - oct 2019</span></li>
        <li><span className="org">m1 enterprise software</span> <span className="time">may 2012 - mar 2016</span></li>
      </ul>
      <h2>traditional education</h2>
      <p>i went to school(s) for computer science/engineering</p>
      <ul>
        <li><span className="org">ualberta</span> <span className="time">jan 2012 - jun 2015</span></li>
        <li><span className="org">itesm</span> <span className="time">aug 2008 - dec 2010</span></li>
      </ul>
      <h2>volunteering</h2>
      <p>i love to volunteer on arts-related events or community-building initiatives</p>
      <p>i'll be volunteering in the <a href="https://www.microsoft.com/en-ca/teals" target="_blank" rel="noopener noreferrer">teals program</a> starting in fall '20</p>
    </>
  );
};

AboutPage.defaultProps = {
};
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export interface HomePageProps {
};

export const HomePage: FC<HomePageProps> = () => {
  return (
    <>
      <h1>hello</h1>
      <p>this is my personal website/scratchpad</p>
      <p>you can also find <Link to="/about">my resume</Link> or <Link to="/contact">my contact info</Link></p>
      <p>cheers!</p>
    </>
  );
};

HomePage.defaultProps = {
};
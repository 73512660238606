import React, { FC } from 'react';

export interface BodyWrapperProps {
};

export const BodyWrapper: FC<BodyWrapperProps> = ({children}) => {
  return (
    <div className="body">
      <div className="content">
        {children}
      </div>
    </div>
  );
};

BodyWrapper.defaultProps = {
};
import React, { FC } from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

export interface FooterProps {
};

export const Footer: FC<FooterProps> = () => {
  return (
    <div className="footer">
      <div className="content">
        <span><Icon iconName="HeartFill" className="love"/> diego</span>
      </div>
    </div>
  );
};

Footer.defaultProps = {
};
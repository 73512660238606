import React, { FC } from 'react';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { CommandBarButton, IButtonProps } from 'office-ui-fabric-react/lib/Button';
import { IContextualMenuItemStyles } from 'office-ui-fabric-react/lib/ContextualMenu';
import { getTheme, concatStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { IButtonStyles } from 'office-ui-fabric-react/lib/Button';
import { memoizeFunction } from 'office-ui-fabric-react/lib/Utilities';
import { useHistory } from 'react-router-dom';

const theme = getTheme();

// Styles for both command bar and overflow/menu items
const itemStyles: Partial<IContextualMenuItemStyles> = {
  label: { fontSize: 18 },
  icon: { color: theme.palette.red },
  iconHovered: { color: theme.palette.redDark },
};

const getCommandBarButtonStyles = memoizeFunction(
  (originalStyles: IButtonStyles | undefined): Partial<IContextualMenuItemStyles> => {
    if (!originalStyles) {
      return itemStyles;
    }

    return concatStyleSets(originalStyles, itemStyles);
  },
);

// Custom renderer for main command bar items
const CustomButton: FC<IButtonProps> = props => {
  let history = useHistory();

  const onClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();

    if (props.href) {
      history.push(props.href);
    }
  }

  return <CommandBarButton {...props} onClick={onClick} styles={getCommandBarButtonStyles(props.styles)} />;
};

export interface TopNavigationProps {
};

export const TopNavigation: FC<TopNavigationProps> = () => {
  const _items: ICommandBarItemProps[] = [
    {
      key: 'name',
      text: 'Diego Luces',
      disabled: true,
    },
    {
      key: 'home',
      text: 'Home',
      iconProps: { iconName: 'Home' },
      href: '/',
    },
    {
      key: 'about',
      text: 'About',
      iconProps: { iconName: 'Contact' },
      href: '/about',
    },
    {
      key: 'contact',
      text: 'Contact',
      iconProps: { iconName: 'ContactInfo' },
      href: '/contact',
    },
  ];

  const _farItems: ICommandBarItemProps[] = [/*
    {
      key: 'info',
      text: 'Info',
      ariaLabel: 'Info',
      iconOnly: true,
      iconProps: { iconName: 'Info' },
      onClick: () => console.log('Info'),
    },
  */];

  return (
    <div className="header">
      <CommandBar
        className="nav"
        // Custom render all buttons
        buttonAs={CustomButton}
        items={_items}
        farItems={_farItems}
        ariaLabel="Use left and right arrow keys to navigate between sections"
      />
    </div>
  );
};

TopNavigation.defaultProps = {
};
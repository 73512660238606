import { createBrowserHistory } from 'history';
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { AboutPage } from './components/AboutPage';
import { AppWrapper } from './components/AppWrapper';
import { BodyWrapper } from './components/BodyWrapper';
import { ContactPage } from './components/ContactPage';
import { Footer } from './components/Footer';
import { HomePage } from './components/HomePage';
import { TopNavigation } from './components/TopNavigation';
import './App.css';

import { initializeIcons } from '@uifabric/icons';
initializeIcons();

function App() {
  return (
    <Router history={createBrowserHistory()}>
      <AppWrapper>
        <TopNavigation />
        <BodyWrapper>
          <Switch>
            <Route path="/" component={HomePage} strict={true} exact={true} />
            <Route path="/about" component={AboutPage} strict={true} exact={true} />
            <Route path="/contact" component={ContactPage} strict={true} exact={true} />
          </Switch>
        </BodyWrapper>
        <Footer />
      </AppWrapper>
    </Router>
  );
}

export default App;
